function Footer() {
  return (
    <div className="bg-green-100 text-center font-serif text-purple-500 font-semibold text-xl p-6 ">
      <div className="">We Love You Nats! 💜 </div>
      <div className="">
        <a href="mailto:welovenats@gmail.com" className="text-sm font-normal">
          Contact
        </a>
      </div>
    </div>
  )
}

export default Footer
