function Header() {
  return (
    <div className="bg-red-200">
      <div className="relative overflow-hidden">
        <div className="relative pt-6 pb-16 sm:pb-24">
          <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
            <div className="text-center">
              <h1 className=" tracking-tight text-gray-900 text-5xl md:text-8xl">
                <span className="block font-display">In Loving Memory</span>
                <span className="block text-indigo-700  font-serif pt-4 pb-2">Natalie Romany</span>
              </h1>
              <p className="mt-3 text-base font-display md:text-2xl ">
                Sunrise 05/23/74 - Sunset 01/04/22
              </p>
              {/* <p className="mt-3 text-base font-serif "></p> */}
              <p className="mt-3 max-w-md mx-auto text-base font-serif text-gray-800 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                This website is dedicated to Natalie Romany, daughter, sister, auntie, friend, and
                wonderful human being. We love you Nats!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
