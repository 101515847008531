function Video(props) {
  const { urls = {} } = props
  const { embed = null, url = null } = urls

  console.log({ embed })

  return (
    <div className="bg-indigo-200 min-h-[400px]">
      <div className="pt-12 pb-12 p-2 lg:p-12">
        <div className="text-center">
          <h2 className="tracking-tight text-gray-900 text-3xl md:text-4xl mb-8">
            <div className="font-display">Service Tuesday, Jan 11th</div>
            <div className="font-serif text-base">5pm New York & 6pm Trinidad</div>
            <div className="font-serif text-xl text-indigo-600">Streaming Live Here</div>
            <a
              href={url}
              className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 mt-6"
            >
              Also on YouTube
            </a>
          </h2>
          <div className="flex justify-center items-center">
            <div
              className="relative h-0 overflow-hidden w-full max-w-5xl"
              style={{ paddingBottom: '56.25%' }}
            >
              <iframe
                title="Nats Video"
                src={embed}
                frameBorder="0"
                allowFullScreen
                className="absolute top-0 left-0 w-full h-full"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Video
