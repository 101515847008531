import { Timestamp, addDoc, collection, getFirestore } from 'firebase/firestore'

import { useForm } from 'react-hook-form'
import { useState } from 'react'

export default function MessageForm({ setState }) {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm()

  const onSubmit = async (dataRaw) => {
    const db = getFirestore()
    const data = { ...dataRaw, created: Timestamp.now() }
    const response = await addDoc(collection(db, 'messages'), data)
    const response2 = await addDoc(collection(db, 'messages_bk'), data)
    setFormSubmitted(true)
    setState({ latest: data })
    console.log({ data })
    console.log({ response })
  }

  return (
    <div className="p-6 bg-white max-w-xl m-auto">
      <h2 className="font-display tracking-tight text-center text-gray-900 text-5xl md:text-6xl mb-8 mt-8">
        Memories
      </h2>

      {formSubmitted && (
        <div className="flex items-start space-x-4">
          <div className="font-serif text-xl italic text-center w-full text-indigo-600">
            Thank You for Submitting your memory.
          </div>
        </div>
      )}

      {!formSubmitted && (
        <div className="flex items-start space-x-4">
          <div className="min-w-0 flex-1">
            <form action="#" className="relative" onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label htmlFor="comment" className="sr-only pb-2">
                  Your Memory of Natalie
                </label>
                <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                  <textarea
                    rows={3}
                    name="comment"
                    id="comment"
                    className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
                    placeholder="Your memory of Natalie..."
                    defaultValue={''}
                    {...register('comment', { required: true })}
                  />
                </div>
                {errors.comment && (
                  <span className="text-red-500 text-sm">A memory is required.</span>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Your Email Address"
                    {...register('email', { required: true })}
                  />
                </div>
                {errors.email && (
                  <span className="text-red-500 text-sm">Your email is required.</span>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="name" className="sr-only">
                  Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Your Name"
                    {...register('name', { required: true })}
                  />
                </div>
                {errors.comment && (
                  <span className="text-red-500 text-sm">Your name is required.</span>
                )}
              </div>

              <input
                type="submit"
                value="Submit Your Memory"
                className="flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              />
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
