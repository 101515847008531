import { collection, deleteDoc, doc, getDoc, getFirestore } from 'firebase/firestore'
import { useEffect, useState } from 'react'

import Footer from 'components/Footer'
import Header from 'components/Header'
import MessageForm from 'components/MessageForm'
import Messages from 'components/Messages'
// import Photos from 'components/Photos'
import Video from 'components/Video'

function Home() {
  const [state, setState] = useState({})
  const [urls, setUrls] = useState({})
  const db = getFirestore()

  const fetch = async () => {
    const docRef = doc(db, 'storage', 'urls')
    const docSnap = await getDoc(docRef)
    const { id } = docSnap
    setUrls({ ...docSnap.data(), id })
  }

  useEffect(() => {
    fetch()
  }, [])

  const onDelete = async ({ id }) => {
    const db = getFirestore()
    await deleteDoc(doc(db, 'messages', id))
  }

  const editPass = new URLSearchParams(window.location.search).get('editPass')
  const isEditable = editPass === 'chineselaundry'

  return (
    <div className="">
      <Header />
      <Video urls={urls} />
      {/* <Photos /> */}
      <MessageForm setState={setState} />
      <Messages state={state} onDelete={onDelete} isEditable={isEditable} />
      <Footer />
    </div>
  )
}

export default Home
