import { collection, getFirestore, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'

import classNames from 'classnames'

function Messages(props) {
  const { state } = props
  const [messages, setMessages] = useState([])
  const db = getFirestore()

  const fetch = async () => {
    const q = query(collection(db, 'messages'), orderBy('created', 'desc'))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = []
      querySnapshot.forEach((doc) => {
        const { id } = doc
        messages.push({ ...doc.data(), id })
      })
      setMessages(messages)
    })
  }

  useEffect(() => {
    fetch()
  }, [])

  return (
    <div className="bg-white min-h-[400px] max-w-xl md:max-w-2xl m-auto">
      {messages.map((message, index) => {
        if (state?.latest?.comment === message.comment && index === 0)
          return <Message {...props} key={index} {...message} myMessage />
        return <Message {...props} key={index} {...message} />
      })}
    </div>
  )
}

function Message(props) {
  const {
    myMessage = false,
    name,
    comment,
    isEditable,
    onDelete,
    // created
  } = props

  return (
    <div
      className={classNames(
        'm-5 p-5 border rounded-lg shadow-sm overflow-hidden bg-indigo-50',
        { 'border-gray-300 text-base': !myMessage },
        { 'border-indigo-600 border-2 text-indigo-700 text-xl': myMessage }
      )}
    >
      <div className="leading-6 font-serif">
        <div className="mb-5 whitespace-pre-line">{comment}</div>
        <b className="font-display text-xl">{name}</b>
        {isEditable && onDelete && (
          <div>
            <button
              type="button"
              onClick={() => onDelete(props)}
              className="flex items-center px-4 py-2 mt-5 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600"
            >
              Delete
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default Messages
