import Home from 'pages/Home'
// import { getAnalytics } from 'firebase/analytics'
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDk8CHxpAx6-NmZm4-ZlZxArFnoMpWynCk',
  authDomain: 'chineselaundrynats.firebaseapp.com',
  projectId: 'chineselaundrynats',
  storageBucket: 'chineselaundrynats.appspot.com',
  messagingSenderId: '136385050200',
  appId: '1:136385050200:web:7bc1a5cbdc76794023fc2d',
  measurementId: 'G-ELV0LMKD7M',
}

// Initialize Firebase
initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)

function App() {
  return <Home />
}

export default App
